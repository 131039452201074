module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/404","/vacancies","/job-alerts","/sitemap"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P8MGD43","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"Accropress"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://31544eb084ae44539700cd4d7e0ca55d@sentry.accropress.com/5","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Redde Northgate","short_name":"Redde Northgate","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"static/img/apple-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d6bbe11d03a1b683a65957eb19de2a79"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
